<template>
  <v-container
    id="about"
    tag="section"
  >
    <base-subheading>Kontakta oss</base-subheading>
    <p>
      Mail: styrelsen (at) nm56.se
    </p>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeAbout',
  }
</script>
